import React, { Suspense } from "react";
import Loader from "../CommanUIComp/Loader/Loader";
import ProtectedRoute from "./ProtectedRoute/protectedRoute";
import DashRouterPage from "../CommanUIComp/DashRouterPage/dashRouterPage";
import { lazyWithRetry } from "./../CommanFunctions/commanFunctions";
const Home = lazyWithRetry(() => import("./../Components/HomePage/Home/home"));
const Jewellery = lazyWithRetry(() => import("./../Components/Jewellery/Jewellery/jewellery"));
const SingleProductJewellery = lazyWithRetry(() => import("./../Components/Jewellery/SingleProductJewellery/singleproductjewellery"));
const Profile = lazyWithRetry(() => import("./../Components/Profile/Profile"));
const Cart = lazyWithRetry(() => import("./../Components/Cart/Cart/cart"));
const Faq = lazyWithRetry(() => import("./../Components/FAQ/Faq/faq"));
const WishList = lazyWithRetry(() => import("./../Components/WishList/wishList"));
const ContentTypeList = lazyWithRetry(() => import("../Components/FAQ/Content/contentType"));
const CertificateDiamond = lazyWithRetry(() => import("../Components/CertificateDiamond/CertificateDiamond"));
const Blog = lazyWithRetry(() => import("../Components/Blog/Blog/blog"));
const BlogDetails = lazyWithRetry(() => import("../Components/Blog/BlogDetails/blogdetails"));
const ContactUs = lazyWithRetry(() => import("./../Components/ContactUs/contactUs"));
const Empty404 = lazyWithRetry(() => import("../CommanUIComp/Empty404/empty404"));
const LooseDiamonds = lazyWithRetry(() => import("./../Components/LooseDiamonds/looseDiamonds"));
const Education = lazyWithRetry(() => import("./../Components/Education/education"));
const AboutUs = lazyWithRetry(() => import("./../Components/AboutUs/aboustUs"));
const PaymentSuccessFail = lazyWithRetry(() => import("./../Components/Cart/PaymentSuccessFail/paymentSuccessFail"));
const Viewjourney = lazyWithRetry(() => import("./../Components/Profile/JourneyCatalogueView/Viewjourney"))
const CreateWithZurah = lazyWithRetry(() => import('./../Components/CreateWithZurah/CreateWithZurah/CreateWithZurah'))
const ZurahDiamondDetail = lazyWithRetry(() => import('./../Components/CreateWithZurah/ZurahDiamondDetail/ZurahDiamondDetail'));
const ZurahJewellery = lazyWithRetry(() => import('./../Components/CreateWithZurah/ZurahJewellery/ZurahJewellery'));
const SinlgeProductDiy = lazyWithRetry(() => import('./../Components/CreateWithZurah/SinlgeProductDiy/SinlgeProductDiy'));
const DiyProduct = lazyWithRetry(() => import('./../Components/CreateWithZurah/DiyProduct/DiyProduct'));
const CampaignPreview = lazyWithRetry(() => import('./../Components/CampaignPreview/campaignPreview'))
// const SearchPage = lazyWithRetry(() => import('./../Components/HeaderFooter/SearchPage/SearchPage'));

export const RouteComponent = [
    // Error page
    {
        path: "*",
        component: Empty404,
        element: <Suspense fallback={<Loader />}><Empty404 /></Suspense>
    },
    // Home Page 
    {
        path: "/",
        component: Home,
        element: <Suspense fallback={<Loader />} ><Home /></Suspense>
    },
    // Refersh Page
    {
        path: "/-",
        component: DashRouterPage,
        element: <Suspense fallback={<Loader />}><DashRouterPage /></Suspense>
    },
    // Reset Password
    {
        path: "/reset-password/:key",
        component: Home,
        element: <Suspense fallback={<Loader />}><Home /></Suspense>
    },
    // Jewellery
    // {
    //     path: "/jewellery",
    //     component: Jewellery,
    //     element: <Suspense fallback={<Loader />} ><Jewellery /></Suspense>
    // },
    {
        path: "/start-with-a-setting",
        component: Jewellery,
        element: <Suspense fallback={<Loader />} ><Jewellery /></Suspense>
    },
    {
        path: "/jewellery/start-with-a-diamond",
        component: Jewellery,
        element: <Suspense fallback={<Loader />} ><Jewellery /></Suspense>
    },
    // {
    //     path: "/jewellery/:productKey/:value",
    //     component: Jewellery,
    //     element: <Suspense fallback={<Loader />} ><Jewellery /></Suspense>
    // },
    // Jewellery Details Page
    // {
    //     path: "/jewellery/:variantId",
    //     component: SingleProductJewellery,
    //     element: <Suspense fallback={<Loader />} ><SingleProductJewellery /></Suspense>
    // },
    // {
    //     path: "/jewellery/:productKey/:value/:variantId",
    //     component: SingleProductJewellery,
    //     element: <Suspense fallback={<Loader />} ><SingleProductJewellery /></Suspense>
    // },
    {
        path: "/start-with-a-setting/:variantId",
        component: SingleProductJewellery,
        element: <Suspense fallback={<Loader />} ><SingleProductJewellery /></Suspense>
    },
    {
        path: "/jewellery/start-with-a-diamond/:variantId",
        component: SingleProductJewellery,
        element: <Suspense fallback={<Loader />} ><SingleProductJewellery /></Suspense>
    },

    // create with zurah page

    {
        path: "/start-with-diamond",
        component: CreateWithZurah,
        element: <Suspense fallback={<Loader />} ><CreateWithZurah /></Suspense>
    },
    {
        path: "/start-with-diamond/shape/:detail",
        component: ZurahDiamondDetail,
        element: <Suspense fallback={<Loader />} ><ZurahDiamondDetail /></Suspense>
    },
    {
        path: "/start-with-diamond/jewellery/:shape",
        component: ZurahJewellery,
        element: <Suspense fallback={<Loader />} ><ZurahJewellery /></Suspense>
    },
    {
        path: "/start-with-diamond/jewellery/:cart/:id",
        component: SinlgeProductDiy,
        element: <Suspense fallback={<Loader />} ><SinlgeProductDiy /></Suspense>
    },
    {
        path: "/start-with-diamond/complete",
        component: DiyProduct,
        element: <Suspense fallback={<Loader />} ><DiyProduct /></Suspense>
    },
    // Frame page
    // {
    //     path: "/product/frame",
    //     component: Jewellery,
    //     element: <Suspense fallback={<Loader />} ><Jewellery /></Suspense>
    // },
    // Frame Details page
    // {
    //     path: "/product/frame/:variantId",
    //     component: SingleProductJewellery,
    //     element: <Suspense fallback={<Loader />} ><SingleProductJewellery /></Suspense>
    // },
    // {
    //     path: "/product/frame/:productKey/:value/",
    //     component: SingleProductJewellery,
    //     element: <Suspense fallback={<Loader />} ><SingleProductJewellery /></Suspense>
    // },
    // {
    //     path: "/product/frame/:productKey/:value/:variantId",
    //     component: SingleProductJewellery,
    //     element: <Suspense fallback={<Loader />} ><SingleProductJewellery /></Suspense>
    // },
    // Certificate Diamond
    {
        path: "/certificate-diamond",
        component: CertificateDiamond,
        element: <Suspense fallback={<Loader />} ><CertificateDiamond /></Suspense>
    },
    {
        path: "/certificate-diamond/:shape/:item",
        component: CertificateDiamond,
        element: <Suspense fallback={<Loader />} ><CertificateDiamond /></Suspense>
    },
    // Certificate DIY
    {
        path: "/certificate-diamond/start-with-a-diamond",
        component: Jewellery,
        element: <Suspense fallback={<Loader />} ><Jewellery /></Suspense>
    },
    {
        path: "/certificate-diamond/start-with-a-setting",
        component: Jewellery,
        element: <Suspense fallback={<Loader />} ><Jewellery /></Suspense>
    },
    {
        path: "/certificate-diamond/start-with-a-diamond/:variantId",
        component: SingleProductJewellery,
        element: <Suspense fallback={<Loader />} ><SingleProductJewellery /></Suspense>
    },
    {
        path: "/certificate-diamond/start-with-a-setting/:variantId",
        component: SingleProductJewellery,
        element: <Suspense fallback={<Loader />} ><SingleProductJewellery /></Suspense>
    },
    // Lab Grown Diamond
    {
        path: "/lab-grown-diamond",
        component: CertificateDiamond,
        element: <Suspense fallback={<Loader />} ><CertificateDiamond /></Suspense>
    },
    {
        path: "/lab-grown-diamond/:shape/:item",
        component: CertificateDiamond,
        element: <Suspense fallback={<Loader />} ><CertificateDiamond /></Suspense>
    },
    // Lab Grown Diamond DIY
    {
        path: "/lab-grown-diamond/start-with-a-diamond",
        component: Jewellery,
        element: <Suspense fallback={<Loader />} ><Jewellery /></Suspense>
    },
    {
        path: "/lab-grown-diamond/start-with-a-setting",
        component: Jewellery,
        element: <Suspense fallback={<Loader />} ><Jewellery /></Suspense>
    },
    {
        path: "/lab-grown-diamond/start-with-a-diamond/:variantId",
        component: SingleProductJewellery,
        element: <Suspense fallback={<Loader />} ><SingleProductJewellery /></Suspense>
    },
    {
        path: "/lab-grown-diamond/start-with-a-setting/:variantId",
        component: SingleProductJewellery,
        element: <Suspense fallback={<Loader />} ><SingleProductJewellery /></Suspense>
    },
    //gemstone ceert
    {
        path: "/lab-grown-gemstone",
        component: CertificateDiamond,
        element: <Suspense fallback={<Loader />} ><CertificateDiamond /></Suspense>
    },
    {
        path: "/lab-grown-gemstone/:shape/:item",
        component: CertificateDiamond,
        element: <Suspense fallback={<Loader />} ><CertificateDiamond /></Suspense>
    },
    // Lab Grown Diamond DIY
    {
        path: "/lab-grown-gemstone/start-with-a-diamond",
        component: Jewellery,
        element: <Suspense fallback={<Loader />} ><Jewellery /></Suspense>
    },
    {
        path: "/lab-grown-gemstone/start-with-a-setting",
        component: Jewellery,
        element: <Suspense fallback={<Loader />} ><Jewellery /></Suspense>
    },
    {
        path: "/lab-grown-gemstone/start-with-a-diamond/:variantId",
        component: SingleProductJewellery,
        element: <Suspense fallback={<Loader />} ><SingleProductJewellery /></Suspense>
    },
    {
        path: "/lab-grown-gemstone/start-with-a-setting/:variantId",
        component: SingleProductJewellery,
        element: <Suspense fallback={<Loader />} ><SingleProductJewellery /></Suspense>
    },
    // Success Order page
    {
        path: "/success-order/:succesOrderId/:OrderAmnt",
        component: PaymentSuccessFail,
        element: <Suspense fallback={<Loader />} > <PaymentSuccessFail /></Suspense>
    },
    {
        path: "/success-order/:succesOrderId",
        component: PaymentSuccessFail,
        element: <Suspense fallback={<Loader />} > <PaymentSuccessFail /></Suspense>
    },
    {
        path: "/success-order",
        component: PaymentSuccessFail,
        element: <Suspense fallback={<Loader />} > <PaymentSuccessFail /></Suspense>
    },
    // Cancel Order page
    {
        path: "/cancel-order/:succesOrderId",
        component: PaymentSuccessFail,
        element: <Suspense fallback={<Loader />} > <PaymentSuccessFail /></Suspense>
    },
    // FAQ page
    {
        path: "/faq",
        component: Faq,
        element: <Suspense fallback={<Loader />} ><Faq /></Suspense>
    },
    // Profile
    {
        path: "/dashboard/",
        component: Profile,
        element: <Suspense fallback={<Loader />} > <Profile />  </Suspense>
    },
    {
        path: "/dashboard/:id",
        component: Profile,
        element: <Suspense fallback={<Loader />} > <Profile />  </Suspense>
    },
    //Order cancle profile
    {
        path: "/profile/order/:true",
        component: Profile,
        element: <Suspense fallback={<Loader />} > <Profile />  </Suspense>
    },
    // Profile Order Page
    {
        path: "/order-details/:orderId",
        component: Profile,
        element: <Suspense fallback={<Loader />} > <ProtectedRoute> <Profile /></ProtectedRoute></Suspense>
    },
    {
        path: "/order-details",
        component: Profile,
        element: <Suspense fallback={<Loader />} > <ProtectedRoute> <Profile /></ProtectedRoute></Suspense>
    },
    // PolicyPage
    {
        path: "/:policyName",
        component: ContentTypeList,
        element: <Suspense fallback={<Loader />} ><ContentTypeList /></Suspense>
    },
    // Cart Page
    {
        path: "/cart",
        component: Cart,
        element: <Suspense fallback={<Loader />} ><Cart /></Suspense>
    },
    // wishList
    {
        path: "/wishList",
        component: WishList,
        element: <Suspense fallback={<Loader />} ><WishList /></Suspense>
    },
    // Blog page
    {
        path: "/blog",
        component: Blog,
        element: <Suspense fallback={<Loader />} ><Blog /></Suspense>
    },
    // Blog Details page
    {
        path: "/blog/blog-details-:unique_id-:category_id",
        component: BlogDetails,
        element: <Suspense fallback={<Loader />} ><BlogDetails /></Suspense>
    },
    {
        path: "/blog-details-:unique_id-:category_id",
        component: BlogDetails,
        element: <Suspense fallback={<Loader />} ><BlogDetails /></Suspense>
    },
    //contact US page
    {
        path: "/contact-us",
        component: ContactUs,
        element: <Suspense fallback={<Loader />} ><ContactUs /></Suspense>
    },
    // About US page
    {
        path: "/about-us",
        component: AboutUs,
        element: <Suspense fallback={<Loader />} ><AboutUs /></Suspense>
    },
    // Loose Diamond Page
    {
        path: "/loose-diamond",
        component: LooseDiamonds,
        element: <Suspense fallback={<Loader />} ><LooseDiamonds /></Suspense>
    },
    {
        path: "/loose-diamond/:shapeName/:shape",
        component: LooseDiamonds,
        element: <Suspense fallback={<Loader />}><LooseDiamonds /></Suspense>
    },
    // Gemston Page
    {
        path: "/gemstone-diamond",
        component: LooseDiamonds,
        element: <Suspense fallback={<Loader />}><LooseDiamonds /></Suspense>
    },
    {
        path: "/gemstone-diamond/:shapeName/:shape",
        component: LooseDiamonds,
        element: <Suspense fallback={<Loader />}><LooseDiamonds /></Suspense>
    },
    // Education
    {
        path: "/education",
        component: Education,
        element: <Suspense fallback={<Loader />}><Education /></Suspense>
    },
    {
        path: "/dashboard/viewjourney/:unique_id",
        component: Viewjourney,
        element: <Viewjourney />
    },
    {
        path: "/dashboard/viewjourney/:unique_id/:type",
        component: Viewjourney,
        element: <Viewjourney />
    },
    {
        path: "/profile/journey-catalog/:type",
        component: Profile,
        element: <Profile />
    },
    {
        path: "/profile/journey-catalog/",
        component: Profile,
        element: <Profile />
    },
    //products
    {
        path: "/products/:verticalCode",
        component: Jewellery,
        element: <Suspense fallback={<Loader />} ><Jewellery /></Suspense>
    },
    {
        path: "/products/:verticalCode/start-with-a-setting",
        component: Jewellery,
        element: <Suspense fallback={<Loader />} ><Jewellery /></Suspense>
    },
    {
        path: "/products/:verticalCode/start-with-a-diamond",
        component: Jewellery,
        element: <Suspense fallback={<Loader />} ><Jewellery /></Suspense>
    },
    {
        path: "/products/:verticalCode/:productKey/:value",
        component: Jewellery,
        element: <Suspense fallback={<Loader />} ><Jewellery /></Suspense>
    },
    // Jewellery Details Page
    {
        path: "/products/:verticalCode/:variantId",
        component: SingleProductJewellery,
        element: <Suspense fallback={<Loader />} ><SingleProductJewellery /></Suspense>
    },
    {
        path: "/products/:verticalCode/:productKey/:value/:variantId",
        component: SingleProductJewellery,
        element: <Suspense fallback={<Loader />} ><SingleProductJewellery /></Suspense>
    },
    {
        path: "/products/:verticalCode/start-with-a-setting/:variantId",
        component: SingleProductJewellery,
        element: <Suspense fallback={<Loader />} ><SingleProductJewellery /></Suspense>
    },
    {
        path: "/product/:verticalCode/start-with-a-diamond/:variantId",
        component: SingleProductJewellery,
        element: <Suspense fallback={<Loader />} ><SingleProductJewellery /></Suspense>
    },
    {
        path: "/campaign/:campaign_id?/:email?/:un_id?/:name?",
        component: CampaignPreview,
        element: <Suspense fallback={<Loader />} ><CampaignPreview /></Suspense>
    },
    // {
    //     path: "/search/:searchWord",
    //     component: SearchPage,
    //     element: <Suspense fallback={<Loader />} ><SearchPage /></Suspense>
    // },
];