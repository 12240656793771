import React, { useEffect, useState } from "react";
import './breadcrumb.scss';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { json, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { diamondPageChnages } from "../../Redux/action";
const BreadcrumbModule = (props) => {
    const [pathName, setPathName] = useState([]);
    const location = useLocation();
    const dispatch = useDispatch();
    const params = useParams();
    const isJewelDiy = location.pathname.includes("start-with-a-setting");
    const isDiamoDiy = location.pathname.includes("start-with-a-diamond");
    const onlyJewellery = location.pathname.includes("products");
    const onlyBlog = location.pathname.includes("blog");
    const DiamoArray = JSON.parse(sessionStorage.getItem("Diamocode"))
    let vertical = "";
    let chkCapitilize = false;

    const isCapitalized = (str) => {
        return str === str.toUpperCase();
    };
    useEffect(() => {
        setTimeout(() => {
            const name = [];
            const path = window.location.pathname;
            var data = path.split("/");
            vertical = data[2];
            if (vertical !== undefined) {
                chkCapitilize = isCapitalized(vertical);
            }

            var value = "";
            if (data.length > 0) {
                if (isJewelDiy != true && isDiamoDiy != true) {
                    if ((data[1] == 'products' || data[1] == 'campaign') && data.length !== 4) {
                        data[3] = '';
                        if (data[1] == 'campaign' && data.length > 5) {
                            data[4] = '';
                        }
                    }
                    if (data.length > 3) {
                        if (data[1] == 'Viewjourney') {
                            data[3] = '';
                        }
                        // if (data.length > 4) {
                        let lastElement = data[data.length - 1];
                        if (lastElement.includes('pv')) {
                            var splitvalue = lastElement.split("-");
                            var productName = splitvalue.slice(0, splitvalue.length - 1).join(" ");
                            if (productName !== "") {
                                data[data.length - 1] = productName;
                            }
                        }
                        // }

                        for (let c = 0; c < data.length; c++) {
                            if (data[c] !== "") {
                                value = value + '/' + data[c];
                                if (c !== 2) {
                                    if (chkCapitilize == true) {
                                        name.push({ pathname: data[c].split("-").join(" "), value: value + '/' + vertical });
                                    } else {
                                        name.push({ pathname: data[c].split("-").join(" "), value: value });
                                    }
                                }
                            }
                        }
                    } else {
                        if (onlyJewellery) {
                            if (data.length > 2) {
                                let lastElement = data[data.length - 1];
                                var splitvalue = lastElement.split("-");
                                var productName = splitvalue.slice(0, splitvalue.length - 1).join(" ");
                                if (productName !== "") {
                                    data[data.length - 1] = productName;
                                }
                            }
                            for (let c = 0; c < data.length; c++) {
                                if (data[c] !== "") {
                                    value = value + '/' + data[c];
                                    name.push({ pathname: data[c].split("-").join(" "), value: value });
                                }
                            }
                        } else if (onlyBlog) {
                            if (data.length > 2) {
                                let lastElement = data[data.length - 1];
                                var splitvalue = lastElement.split("-");
                                var productName = splitvalue.slice(0, splitvalue.length - 2).join(" ");
                                if (productName !== "") {
                                    data[data.length - 1] = productName;
                                }
                            }
                            for (let c = 0; c < data.length; c++) {
                                if (data[c] !== "") {
                                    value = value + '/' + data[c];
                                    // name.push({ pathname: data[c].split("-").join(" "), value: value });
                                    name.push({
                                        pathname: data[c].split("-").join(" ").length >= 2 ? data[c].split("-").slice(0, 2).join(" ") : data[c].split("-").join(" "),
                                        value: value
                                    });

                                }
                            }
                        } else {
                            for (let c = 0; c < data.length; c++) {
                                if (data[c] !== "") {
                                    value = value + '/' + data[c];
                                    name.push({ pathname: data[c].split("-").join(" ").replace("_", " "), value: value });
                                }
                            }

                        }
                    }
                } else {
                    if (data[1] == 'products') {
                        data[2] = '';
                    }
                    if (data.length > 2) {
                        let lastElement = data[data.length - 1];
                        var splitvalue = lastElement.split("-");
                        var productName = splitvalue.slice(0, splitvalue.length - 1).join(" ");
                        if (productName !== "") {
                            data[data.length - 1] = productName;
                        }
                    }
                    for (let c = 0; c < data.length; c++) {
                        if (data[c] !== "") {
                            name.push({ pathname: data[c].split("-").join(" "), value: '/' + data[c] });
                        }
                    }
                }
            }
            // if (JSON.stringify(name).includes('start-with')) {
            //     name.splice(0, 1)
            // }
            if (JSON.stringify(name).includes('cancel-order')) {
                name.splice(0, 1)
            }
            if (JSON.stringify(name).includes('success-order')) {
                name.splice(0, 1)
            }

            setPathName([...name]);
            if (window.location.pathname.includes("products")) {
                let text = "";
                const linkArr = [];
                const urlArr = window.location.pathname.split("/");
                const filterArr = urlArr.filter((item) => item !== "");

                const lastElementName = filterArr[filterArr.length - 1];
                if (lastElementName.includes('pv')) {
                    const splitVal = lastElementName.split("-");
                    const productName = splitVal.slice(0, splitVal.length - 1).join(" ");
                    if (productName !== "") {
                        filterArr[filterArr.length - 1] = productName;
                    }
                }
                for (let i = 0; i < filterArr.length; i++) {
                    if (filterArr[2] == 'campaign') {
                        filterArr[3] = '';
                    }
                    text += '/' + filterArr[i];
                    if (filterArr.length >= 4 && filterArr[i] != '') {
                        if (i !== 2) {
                            if (filterArr[i] === "products") {
                                linkArr.push({ pathname: filterArr[i].split("-").join(" "), value: text + '/' + vertical });
                            } else {
                                linkArr.push({ pathname: filterArr[i].split("-").join(" "), value: text });
                            }
                        };
                    } else if (filterArr[i] != '') {
                        if (filterArr[i] === "products") {
                            linkArr.push({ pathname: filterArr[i].split("-").join(" "), value: text + '/' + vertical });
                        } else {
                            linkArr.push({ pathname: filterArr[i].split("-").join(" "), value: text });
                        }
                    }
                }
                const newLinks = linkArr.filter((item) => item.pathname !== vertical);
                setPathName(newLinks);
            }
            if (location.pathname.split("/").includes("order-details") && location.pathname.split("/").length === 3) {
                let text = "";
                const orderDetailsUrlArr = location.pathname.split("/");
                const linkOrderDetails = [];
                for (let i = 0; i < orderDetailsUrlArr.length; i++) {
                    const element = orderDetailsUrlArr[i];
                    if (element !== "") {
                        text += '/' + orderDetailsUrlArr[i];
                        if (element === "order-details") {
                            linkOrderDetails.push({ pathname: element.split("-").join(" "), value: text });
                        } else {
                            linkOrderDetails.push({ pathname: "My Order", value: text });
                        }
                    };
                }
                setPathName(linkOrderDetails);
            };
        }, 10);
    }, [isJewelDiy, isDiamoDiy, pathName]);

    return (
        <React.Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {location.pathname !== "/" &&
                            <Breadcrumbs maxItems={10} aria-label="breadcrumb" className={`${isJewelDiy != true && isDiamoDiy != true ? "Breadcrumb_inner py-2" : "Breadcrumb_inner_diy py-2"}`}>
                                <Link underline="hover" color="inherit" href="/">
                                    Home
                                </Link>
                                {pathName.length > 0 && pathName.map((c, index) => {
                                    return (
                                        (pathName.length - 1) !== index ?
                                            <Link underline="hover" color="inherit" href={`${c.value}`} key={index} onClick={() => { dispatch(diamondPageChnages(false)) }}>{c.pathname}</Link>
                                            :
                                            <Typography color="text.primary" key={index}>{DiamoArray !== null && DiamoArray.length > 0 || isDiamoDiy !== null || isDiamoDiy !== null ? c.pathname : "All"}</Typography>
                                    )
                                })}
                            </Breadcrumbs>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BreadcrumbModule;